.App {
  text-align: center;
  /* background-color: #1B163F; */
}

header {
  background-image: url('./images/header.jpeg');
  background-size: cover;
  background-position: center;
  height: 35vh;
}

footer {
  background-image: url('./images/footer.jpeg');
  background-size: cover;
  background-position: center;
  height: 35vh;
}

form input {
  padding: 5px 10px;
}

form label {
  display: block;
}

form button {
  display: block;
  margin: 10px auto;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}