* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

form {
    width: 60%;
    max-width: 500px;
    padding: 20px;
    margin: 0 auto;
}

form input {
    width: 100%;
    padding: 5px 10px;
    text-align: center;
}

select {
    width: 100%;
    padding: 5px 10px;
    text-align: center;
}

form label {
    display: block;
}

form button {
    display: block;
    margin: 10px 0;
    padding: 10px 50px;
    background-color: rgb(118, 0, 229);
    color: white;
    font-size: 20px;
    border: none;
    border-radius: 5px;
}

form button:hover {
    background-color: rgba(118, 0, 229, .8);
}